import { ScrcpyMediaStreamPacket } from "@10xminds/scrcpy";
import { TransformStream } from "@yume-chan/stream-extra";
import { OpusDecodedAudio, OpusDecoderWebWorker } from "opus-decoder";

export class AacDecodeStream extends TransformStream<
  ScrcpyMediaStreamPacket,
  Float32Array[]
> {
  constructor(config: AudioDecoderConfig) {
    let decoder: AudioDecoder;
    super({
      start(controller) {
        decoder = new AudioDecoder({
          error(error) {
            console.log("audio decoder error: ", error);
            controller.error(error);
          },
          output(output) {
            controller.enqueue(
              Array.from({ length: 2 }, (_, i) => {
                const options: AudioDataCopyToOptions = {
                  // AAC decodes to "f32-planar",
                  // converting to another format may cause audio glitches on Chrome.
                  format: "f32-planar",
                  planeIndex: i,
                };
                const buffer = new Float32Array(
                  output.allocationSize(options) /
                    Float32Array.BYTES_PER_ELEMENT
                );
                output.copyTo(buffer, options);
                return buffer;
              })
            );
          },
        });
      },
      transform(chunk) {
        switch (chunk.type) {
          case "configuration":
            // https://www.w3.org/TR/webcodecs-aac-codec-registration/#audiodecoderconfig-description
            // Raw AAC stream needs `description` to be set.
            decoder.configure({
              ...config,
              description: chunk.data,
            });
            break;
          case "data":
            decoder.decode(
              new EncodedAudioChunk({
                data: chunk.data,
                type: "key",
                timestamp: 0,
              })
            );
        }
      },
      async flush() {
        await decoder!.flush();
      },
    });
  }
}

export class OpusDecodeStream extends TransformStream<
  ScrcpyMediaStreamPacket,
  Float32Array
> {
  constructor(config: AudioDecoderConfig) {
    console.log("using default opus decoder");
    let decoder: AudioDecoder;
    super({
      start(controller) {
        decoder = new AudioDecoder({
          error(error) {
            console.log("audio decoder error: ", error);
            controller.error(error);
          },
          output(output) {
            // Opus decodes to "f32",
            // converting to another format may cause audio glitches on Chrome.
            const options: AudioDataCopyToOptions = {
              format: "f32",
              planeIndex: 0,
            };
            const buffer = new Float32Array(
              output.allocationSize(options) / Float32Array.BYTES_PER_ELEMENT
            );
            output.copyTo(buffer, options);
            controller.enqueue(buffer);
          },
        });
        decoder.configure(config);
      },
      transform(chunk) {
        switch (chunk.type) {
          case "configuration":
            // configuration data is a opus-in-ogg identification header,
            // but stream data is raw opus,
            // so it has no use here.
            break;
          case "data":
            if (chunk.data.length === 0) {
              break;
            }
            decoder.decode(
              new EncodedAudioChunk({
                type: "key",
                timestamp: 0,
                data: chunk.data,
              })
            );
        }
      },
      async flush() {
        await decoder!.flush();
      },
    });
  }
}

export class CustomOpusDecodeStream extends TransformStream<
  ScrcpyMediaStreamPacket,
  Float32Array
> {
  constructor() {
    console.log("using custom opus decoder");
    let decoder: OpusDecoderWebWorker;
    super({
      async start() {
        decoder = new OpusDecoderWebWorker({
          channels: 1,
        });
        await decoder.ready;
      },
      transform(chunk, controller) {
        const playAudio = ({ channelData }: OpusDecodedAudio) => {
          controller.enqueue(channelData[0]);
        };
        if (chunk.type === "data" && chunk.data.length !== 0) {
          decoder.decodeFrame(chunk.data).then(playAudio);
        }
      },
      async flush() {
        await decoder.reset();
      },
    });
  }
}
