import {
  AndroidMotionEventAction,
  AndroidMotionEventButton,
  ScrcpyPointerId,
} from "@10xminds/scrcpy";
import { makeStyles } from "@griffel/react";
import { MouseEvent, PointerEvent, useEffect, useState } from "react";
import { STATE } from "./state";

const useClasses = makeStyles({
  video: {
    transformOrigin: "center center",
    touchAction: "none",
  },
});

function handleWheel(e: WheelEvent) {
  if (!STATE.client) {
    return;
  }

  STATE.fullScreenContainer!.focus();
  e.preventDefault();
  e.stopPropagation();

  const { x, y } = STATE.clientPositionToDevicePosition(e.clientX, e.clientY);
  STATE.client!.controller!.injectScroll({
    screenWidth: STATE.client!.screenWidth!,
    screenHeight: STATE.client!.screenHeight!,
    pointerX: x,
    pointerY: y,
    scrollX: -e.deltaX / 100,
    scrollY: -e.deltaY / 100,
    buttons: 0,
  });
}

const MOUSE_EVENT_BUTTON_TO_ANDROID_BUTTON = [
  AndroidMotionEventButton.Primary,
  AndroidMotionEventButton.Tertiary,
  AndroidMotionEventButton.Secondary,
  AndroidMotionEventButton.Back,
  AndroidMotionEventButton.Forward,
];

function injectTouch(
  action: AndroidMotionEventAction,
  e: PointerEvent<HTMLDivElement>
) {
  if (!STATE.client) {
    return;
  }

  const { pointerType } = e;
  let pointerId: bigint;
  if (pointerType === "mouse") {
    // Android 13 has bug with mouse injection
    // https://github.com/Genymobile/scrcpy/issues/3708
    pointerId = ScrcpyPointerId.Finger;
  } else {
    pointerId = BigInt(e.pointerId);
  }

  const { x, y } = STATE.clientPositionToDevicePosition(e.clientX, e.clientY);

  const messages = STATE.hoverHelper!.process({
    action,
    pointerId,
    screenWidth: STATE.client.screenWidth!,
    screenHeight: STATE.client.screenHeight!,
    pointerX: x,
    pointerY: y,
    pressure: e.pressure,
    actionButton: MOUSE_EVENT_BUTTON_TO_ANDROID_BUTTON[e.button],
    // `MouseEvent.buttons` has the same order as Android `MotionEvent`
    buttons: e.buttons,
  });
  for (const message of messages) {
    STATE.client.controller!.injectTouch(message);
  }
}

function handlePointerDown(e: PointerEvent<HTMLDivElement>) {
  if (!STATE.client) {
    return;
  }

  STATE.fullScreenContainer!.focus();
  e.preventDefault();
  e.stopPropagation();

  e.currentTarget.setPointerCapture(e.pointerId);
  injectTouch(AndroidMotionEventAction.Down, e);
}

function handlePointerMove(e: PointerEvent<HTMLDivElement>) {
  if (!STATE.client) {
    return;
  }

  e.preventDefault();
  e.stopPropagation();
  injectTouch(
    e.buttons === 0
      ? AndroidMotionEventAction.HoverMove
      : AndroidMotionEventAction.Move,
    e
  );
}

function handlePointerUp(e: PointerEvent<HTMLDivElement>) {
  if (!STATE.client) {
    return;
  }

  e.preventDefault();
  e.stopPropagation();
  injectTouch(AndroidMotionEventAction.Up, e);
}

function handlePointerLeave(e: PointerEvent<HTMLDivElement>) {
  if (!STATE.client) {
    return;
  }
}

function handleContextMenu(e: MouseEvent<HTMLDivElement>) {
  e.preventDefault();
}

export function VideoContainer() {
  const classes = useClasses();

  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    STATE.setRendererContainer(container);

    if (!container) {
      return;
    }

    container.addEventListener("wheel", handleWheel, {
      passive: false,
    });

    return () => {
      container.removeEventListener("wheel", handleWheel);
    };
  }, [container]);

  return (
    <div
      ref={setContainer}
      className={classes.video}
      style={{
        width: STATE.width,
        height: STATE.height,
        transform: `translate(${
          (STATE.rotatedWidth - STATE.width) / 2
        }px, ${(STATE.rotatedHeight - STATE.height) / 2}px) rotate(${
          STATE.rotation * 90
        }deg)`,
      }}
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
      onPointerCancel={handlePointerUp}
      onPointerLeave={handlePointerLeave}
      onContextMenu={handleContextMenu}
    />
  );
}
